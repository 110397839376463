var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.svg
    ? _c("div", {
        staticStyle: {
          height: "0",
          width: "0",
          position: "absolute",
          visibility: "hidden"
        },
        domProps: { innerHTML: _vm._s(_vm.svg) }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }